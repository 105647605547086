import React, { memo } from "react";
import { graphql } from "gatsby";
//import Slider from "react-slick";
import { breakpoints, wrapper } from "../utils/style";
import Layout from "../components/shared/layout";
import SEO from "../components/SEO/SEO";
import styled from "@emotion/styled";
import withLocation from "../components/shared/withLocation";
import PlayForFree from "../components/Content/Home/PlayForFree";
//import GetWindowWidth from "../components/shared/GetWindowWidth";
import Parallax from "../components/shared/Backgrounds/Parallax";
import SlickSlide from '../components/shared/SlickSlide';
import RecentPosts from '../components/shared/RecentPosts';
import BottomBanner from '../components/Content/Home/BottomBanner';
import PlayBestLottery from '../components/Content/Home/PlayBestLottery';
import SiteDescription from '../components/Content/Home/SiteDescrition';

const WrapperDesktop = styled("div")`
  ${wrapper}
`;

/*const SlickWrapper = styled("div")`
  padding-bottom: 5px;
  max-width: 1224px;
  margin: 0 auto;
  .slick-dots {
    bottom: 10px;
  }
  .desktop-view {
    margin: 0 -345px;
    .slick-slide {
      padding: 0 30px;
      opacity: 0.3;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 30px;
    }
    &.no-padding {
      margin: 0;
    }
  }
  .mobile-view {
    display: none;

    .slick-slide {
      padding: 0 5px;
      opacity: 0.5;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
  ${Slick}
  @media (max-width: ${breakpoints.xl}) {
    .desktop-view {
      margin: 0 -325px;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .desktop-view {
      margin: 0 -320px;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: 1000px) {
    .desktop-view {
      margin: 0 -300px;

      .slick-slide {
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 750px) {
    .mobile-view {
      display: block;
    }
    .desktop-view {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .slick-dots {
      bottom: 0;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .slick-prev,
    .slick-next {
      display: none;
    }
  }
`;*/

const WrapperBanner = styled('div')`
  ${wrapper};
  margin: 0 auto;
  padding: 5px 10px;
  .gatsby-image-wrapper {
    border-radius: 30px;
  }
  .mobile-view { display: none; }
  @media (max-width: ${breakpoints.sm}) {
    padding: 0 15px;
    .mobile-view { display: block; }
    .desktop-view { display: none; }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
`;


const HomePage = ({ data }) => {
  //const windowWidth = GetWindowWidth();
  //const isDesktop = windowWidth > 750;
  const homePageNode = data.allWordpressPage.edges[0].node;
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER;
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : "";

  const slider = homePageNode.acf.top_slider;
  //const featuredMedia = homePageNode.featured_media;
  //const sliderMobile = homePageNode.acf.top_slider_mobile;
  const showSlider = slider && slider.length > 0;
  const slide1Desktop = slider[0];
  //const showSliderMobile = sliderMobile && sliderMobile.length > 0;
  //const slide1Mobile = sliderMobile[0];
  const showPlayForFree = homePageNode.acf.play_for_free;
  const playForFreeIframe = homePageNode?.acf?.play_for_free_video_file;
  const playForFreeTitle = homePageNode.acf.play_for_free_title;
  const playForFreeVideoCover = homePageNode.acf.play_for_free_video_cover;
  const lotteries = homePageNode.acf.lotteries;

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} showSchema={true} />
      <Parallax />
      <div id="home-content-all" className="inner-content with-overflow-slider">
        {showSlider &&
        <WrapperBanner>
          <SlickSlide
            key={slide1Desktop.path}
            img={slide1Desktop.localFile}
            altText={slide1Desktop.alt_text}
            line1={slide1Desktop.acf ? slide1Desktop.acf.slide_title : ''}
            line2={slide1Desktop.acf ? slide1Desktop.acf.slide_description : ''}
            link={slide1Desktop.acf ? slide1Desktop.acf.slide_url : ''}
            ext={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('http') !== -1}
            anchor={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('#') !== -1}
          />
          {/*<div className="desktop-view">
            <SlickSlide
              key={slide1Desktop.path}
              img={slide1Desktop.localFile}
              altText={slide1Desktop.alt_text}
              line1={slide1Desktop.acf ? slide1Desktop.acf.slide_title : ''}
              line2={slide1Desktop.acf ? slide1Desktop.acf.slide_description : ''}
              link={slide1Desktop.acf ? slide1Desktop.acf.slide_url : ''}
              ext={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('http') !== -1}
              anchor={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('#') !== -1}
            />
          </div>*/}
          {/*showSliderMobile &&
          <div className="mobile-view">
            <SlickSlide
              key={slide1Mobile.path}
              img={slide1Mobile.localFile}
              altText={slide1Mobile.alt_text}
              line1={slide1Mobile.acf ? slide1Mobile.acf.slide_title : ''}
              line2={slide1Mobile.acf ? slide1Mobile.acf.slide_description : ''}
              link={slide1Mobile.acf ? slide1Mobile.acf.slide_url : ''}
              ext={slide1Mobile.acf && slide1Mobile.acf.slide_url.indexOf('http') !== -1}
              anchor={slide1Mobile.acf && slide1Mobile.acf.slide_url.indexOf('#') !== -1}
            />
          </div>
          */}

        </WrapperBanner>

        }

        <WrapperDesktop>
          <SiteDescription title={homePageNode.title} description={homePageNode.content}
          banner1={homePageNode.acf.description_banner_1} banner2={homePageNode.acf.description_banner_2}
          balls={data.balls}/>

          {lotteries.lotteries.length &&
          <PlayBestLottery title={lotteries.lotteries_title} subtitle={lotteries.lotteries_subtitle}
                           lottery_images={lotteries.lotteries}/>}

          {showPlayForFree &&
          <PlayForFree iframe={playForFreeIframe?.source_url} title={playForFreeTitle} video_cover={playForFreeVideoCover}
                       play_free={showPlayForFree} />}
          <RecentPosts/>
          <BottomBanner banner={homePageNode.acf.bottom_banner}/>
        </WrapperDesktop>
      </div>
    </Layout>
  )
};

export default memo(withLocation(HomePage))

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            play_for_free_title
            play_for_free_video_file {
              link
              source_url
            }
            play_for_free_video_cover {
              alt_text
              title
              path
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            play_for_free {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            
            bottom_banner {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1190, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            
            description_banner_1 {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            
            description_banner_2 {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            
            lotteries {
              lotteries_title
              lotteries_subtitle
              lotteries {
                color
                link
                tex
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    balls: file(relativePath: { eq: "home/site-description.png" }) {
      childImageSharp {
        fluid(maxWidth: 256, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
