import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints, color } from '../../utils/style';
import Oval1 from '../../icons/svg/Backgrounds/Oval1';

const SlideWrap = styled('div')`
  padding: 1px;
  background: ${color.azul};
  background-image: linear-gradient(to left, #57ffeb, #ff989c);
  border-radius: 110px;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
  .slide-div {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    background: ${color.white};
    background-image: linear-gradient(to right, #fff9f8, #effffd), linear-gradient(to left, #57ffeb, #ff989c 2%);
    border-radius: 110px;
    &:hover, &:active {
      text-decoration: none;
      .button {
        background: ${color.red};
      }
    }
  }
  .img-part {
    flex-basis: 47%;
    border-radius: 0 110px 110px 0;
    position: relative;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    border-radius: 0 110px 110px 0;
  }
  a:hover, a:active {
    text-decoration: none;
  }
  @media (max-width: ${breakpoints.lg}) {
    border-radius: 80px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
    .slide-div {
      border-radius: 80px;
    }
    .img-part, .gatsby-image-wrapper {
      border-radius: 0 80px 80px 0;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    border-radius: 50px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
    .slide-div, .img-part {
      border-radius: 50px;
    }
    .slide-div {
      flex-wrap: wrap;
      flex-direction: row;
    }
    .img-part {
      flex-basis: 100%;
      padding: 10px;
    }
    .gatsby-image-wrapper {
      border-radius: 44px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .img-part {
      padding: 0 10px;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    .img-part {
      padding: 0 25px;
    }
  }
`;

const TextBlock = styled('div')`
  flex-basis: 47%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding-left: 80px;
  text-align: left;
  position: relative;
  .text-block-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    .oval-1 {
      position: absolute;
      left:-9%;
      top: 20px;
    }
    .oval-2 {
      position: absolute;
      right:-54px;
      top: 40%;
    }
    .oval-3 {
      position: absolute;
      right:120px;
      bottom: 60px;
    }
  }
  .intro {
    display: block;
    position: relative;
    color: ${color.red};
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 25px;
  }
  .line-1, .line-2 {
    display: block;
    position: relative;
    font-size: 60px;
    line-height: 1.13;
    font-weight: 700;
    color: #fa6751;
  }
  .line-1 {
    background: -webkit-linear-gradient(#ffac6c, #fa6751);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .line-2 {
    background: -webkit-linear-gradient(#fa6751, #f63940);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
  .line-3 {
    display: block;
    position: relative;
    margin: 25px 0;
    color: ${color.black};
    font-size: 20px;
    line-height: 1.6;
  }
  
  @media (max-width: ${breakpoints.md}) {
    padding-left: 60px;
    .intro {
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 20px;
    }
    .line-1, .line-2 {
      font-size: 36px;
    }
    .line-3 {
      font-size: 16px;
      ine-height: 1.3;
      margin: 20px 0;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    flex-basis: 100%;
    text-align: center;
    padding: 20px 20px 30px;
    .text-block-bg {
      display: none;
    }
    .line-1, .line-2 {
      display: inline;
      font-size: 24px;
      line-height: 1.13;
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 20px;
  }
  
  
`;

const SlickSlide = ({ img, altText, line1, line2, link, ext, anchor } ) => {
  const { childImageSharp = {} } = img || {};

  return (
    <SlideWrap>
      {!link ?
      <div className="slide-div">
        <div className="img-part">
          <Img fluid={childImageSharp.fluid} alt={altText ? altText : 'slide'} fadeIn={false} loading='eager' />
        </div>
        {(line1 || line2) &&
        <TextBlock className="text-block">
          <div className="text-block-bg">
            <Oval1 width={218} height={218} className={"oval-1"}/>
            <Oval1 width={133} height={133} className={"oval-2"}/>
            <Oval1 width={57} height={57} className={"oval-3"}/>
          </div>
          {line1 &&
            <div className="intro">{line1}</div>
          }
          {line2 &&
            <div className="major" dangerouslySetInnerHTML={{ __html: line2 }} />
          }
        </TextBlock>
        }
      </div>
      : <a className="slide-div" href={(ext || anchor) ? link :
          process.env.FRONTEND_PROTOCOL + '://' +  process.env.FRONTEND_DOMAIN + link}>
          <div className="img-part">
            <Img fluid={childImageSharp.fluid} alt={altText ? altText : 'slide'} fadeIn={false} loading='eager' />
          </div>
          {(line1 || line2) &&
          <TextBlock className="text-block">
            <div className="text-block-bg">
              <Oval1 width={218} height={218} className={"oval-1"}/>
              <Oval1 width={133} height={133} className={"oval-2"}/>
              <Oval1 width={57} height={57} className={"oval-3"}/>
            </div>
            {line1 &&
              <div className="intro">{line1}</div>
            }
            {line2 &&
              <div className="major" dangerouslySetInnerHTML={{ __html: line2 }} />
            }
            <div>
              <span className="button red-button wider">เล่นเลย</span>
            </div>
          </TextBlock>
          }
        </a>
      }
    </SlideWrap>
  )
};

SlickSlide.propTypes = {
  img: PropTypes.object.isRequired,
  altText: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  link: PropTypes.string,
  ext: PropTypes.bool,
  anchor: PropTypes.bool,
};

export default SlickSlide;
