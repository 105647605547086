import React, { memo } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, color, ContentStyles } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const SiteDescriptionWrap = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 60px 0;
  position: relative;
  .text-block {
    flex-basis: 63%;
    margin-left: 20px;
    padding: 25px 40px 50px;
    border-radius: 100px 20px 100px 20px;
    background: ${color.white};
    background-image: linear-gradient(133deg, #fff9f8 18%, #effffd 85%);
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
  }
  .banners-block {
    flex-basis: 31.6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .gatsby-image-wrapper {
      border-radius: 20px;
    }
  }
  .desc-banner {
    display: block;
    border-radius: 20px;
    &:hover, &:active {
      box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    }
  }
  .balls-container {
    width: 275px;
    position: absolute;
    left:-12px;
    top: -20px;
  }
  
  .text-block .h1 {
    padding:0 50px 0 210px;
    font-size: 40px;
    line-height: 1.4;
  }
  @media (max-width: ${breakpoints.lg}) {
    .balls-container {
      & + .text-block .h1 {
        padding-right:0;
      }
    }
    .banners-block {
      justify-content: flex-start;
    }
    .desc-banner {
      margin-bottom: 25px;
    }
  }  
  @media (max-width: ${breakpoints.md}) {
    .text-block {
      flex-basis: 100%; 
    }
    .balls-container {
      width: 250px;
      top: -40px;
      & + .text-block .h1 {
        min-height: 68px;
      }
    }
    .banners-block {
      flex-basis: 100%;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-top: 30px;
    }
    .desc-banner {
      flex-basis: 48%;
    }
  }  
  @media (max-width: ${breakpoints.sm}) {
    .text-block {
      padding: 150px 25px 25px;
      border-radius: 60px 20px 60px 20px;
      margin: 0;
      .h1 {
        padding: 0;
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    .balls-container {
      left: 50%;
      margin-left: -125px;
      & + .text-block .h1 {
        padding:0;
      }
    } 
    .banners-block {
      display: none;
    }
  }
`;

const ContentWrapper = styled("div")`
  margin-top: 20px;
  ${ContentStyles}
  .show-more {
    display: none;
    margin-top: 10px;
    .more-span {
      display: inline-flex;
    }
    .less-span {
      display: none;
    }
  }
  .show-less {
    .more-span {display: none;}
    .less-span {display: inline-flex;}
  }
  .home-text-content {
    line-height: 2;
    max-height: 245px;
    overflow-y: auto;
    padding-right: 10px;
    h2 {
      font-size: 30px;
    }
    h3, h4 {
      font-size: 20px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .home-text-content {
      max-height: 150px;
      padding-right: 0;
      overflow: hidden;
      h2 {
        font-size: 20px;
      }
      h3, h4 {
        font-size: 18px;
      }
    }
    .full-height {
      max-height: 50000px;
      & + .show-more {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .show-more {
      display: inline-flex;
      color: ${color.red};
      background: transparent;
      padding: 0;
      &:hover, &:active {
        background: transparent;
      }
      svg {
        margin-left: 10px;
      }
    }
    @media (max-width: ${breakpoints.sm}) {
      margin-top: 0;
    }
  }
`;



const SiteDescription = ({ title, description, banner1, banner2, balls }) => {
  const handleMoreClick = e => {
    e.preventDefault();
    const target = document.getElementById("home-text-content");
    target.classList.toggle("full-height");
    const self = document.getElementById("home-more-less");
    self.classList.toggle("show-less");
  };

  return (
    <SiteDescriptionWrap>
      {balls.childImageSharp?.fluid &&
      <div className="balls-container">
        <Img
          fluid={balls.childImageSharp.fluid}
          alt="Lottoasian description"
          fadeIn={false}
          loading='eager'
        />
      </div>
      }
      <div className="text-block">
        <h1 className="h1 gradient">
          {title}
        </h1>
        {description && (
          <ContentWrapper>
            <div id="home-text-content" className="home-text-content" dangerouslySetInnerHTML={{ __html: description }} />
            <button
              id="home-more-less" aria-label="Show more"
              className="button no-button show-more"
              onClick={e => handleMoreClick(e)}
            >
              <span className="more-span">อ่านเพิ่มเติม</span>
              <span className="less-span">แสดงน้อยลง</span>
              <IcomoonIcon icon="arrow-down-simple" color={color.red} size={16}/>
            </button>
          </ContentWrapper>
        )}
      </div>
      <div className="banners-block">
        {banner1.acf.slide_url ?
          <a className="desc-banner" href={banner1.acf.slide_url}>
            <Img
              fluid={banner1.localFile.childImageSharp.fluid}
              alt={banner1.alt_text}
              fadeIn={false}
              loading='eager'
            />
          </a> : <div className="desc-banner">
            <Img
              fluid={banner1.localFile.childImageSharp.fluid}
              alt={banner1.alt_text}
              fadeIn={false}
              loading='eager'
            />
          </div>
        }
        {banner2.acf.slide_url ?
          <a className="desc-banner desc-banner-bottom" href={banner1.acf.slide_url}>
            <Img
              fluid={banner2.localFile.childImageSharp.fluid}
              alt={banner2.alt_text}
              fadeIn={false}
              loading='eager'
            />
          </a> : <div className="desc-banner desc-banner-bottom">
            <Img
              fluid={banner2.localFile.childImageSharp.fluid}
              alt={banner2.alt_text}
              fadeIn={false}
              loading='eager'
            />
          </div>
        }
      </div>
    </SiteDescriptionWrap>
  );
};

SiteDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  banner1: PropTypes.shape({
    alt_text: PropTypes.string,
    acf: PropTypes.shape({
      slide_description: PropTypes.string,
      slide_title: PropTypes.string,
      slide_url: PropTypes.string,
    }),
  }),
  banner2: PropTypes.shape({
    alt_text: PropTypes.string,
    acf: PropTypes.shape({
      slide_description: PropTypes.string,
      slide_title: PropTypes.string,
      slide_url: PropTypes.string,
    }),
  }),
};

SiteDescription.defaultProps = {
  title: '',
  description: '',
  banner1: {},
  banner2: {},
  balls: {}
};

export default memo(SiteDescription);
